.loading-screen {
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
}
.lobby-spinner {
  margin: auto;
}
.panel {
  z-index: 5;
  position: relative;
  display: block;
}
.error {
  position: absolute;
  top: 0;
  left: 0;
}
