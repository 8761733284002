.header-main {
  background-color: rgba(20, 0, 39, 0);
  text-align: center;
  width: 100vw;

  h1 {
    color: white;
  }
  .header-lang-selector {
    * {
      margin: auto;
    }
  }
}

.header-settings-selector {
  width: 15vh;
  height: 15vh;
  right: 0;
  top: 0;
  display: flex;
  float: right;
  position: absolute;

  * {
    width: 3vh;
    height: 3vh;
    margin: auto;
    cursor: pointer;
  }
  *:hover {
    width: 3.5vh;
    height: 3.5vh;
  }
}

.header-logo {
  height: 7vh;
  width: 7vh;
  margin-left: 4vh;
  position: absolute;
}
@media only screen and (max-width: 800px) {
  .header-lang-selector {
    margin: auto;
    margin-top: 4vh;
    margin-bottom: -6vh;
  }
  .header-settings-selector {
    display: none;
  }
  .header-title {
    display: none;
  }
  .header-logo {
    height: 7vh;
    width: 7vh;
    margin: auto;
    position: relative;
  }
}
