.sitting-robot {
  width: 100vw;
  display: flex;
  .sitting-robot-container {
    width: 20vh;
    margin: auto;
    margin-top: 10vh;
  }

  .sitting-robot-eye1 {
    width: 1vh;
    height: 1vh;
    background-color: rgb(53, 52, 52);
    position: absolute;
    margin-top: -15.8vh;
    margin-left: 6.8vh;
    border-radius: 100%;
  }
  .sitting-robot-eye2 {
    width: 1vh;
    height: 1vh;
    background-color: rgb(53, 52, 52);
    position: absolute;
    margin-top: -15.8vh;
    margin-left: 10.9vh;
    border-radius: 100%;
  }
  .sitting-robot-eyes {
  }
}
