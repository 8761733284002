.logo-items {
  width: 10vw;
  height: 9.5vw;
  margin: auto;
}
.logo-hat {
  position: absolute;
  z-index: 1;
  display: block;
  transform: rotate(20deg);
}

.logo-trophy {
  position: absolute;
  z-index: 2;
  display: block;
  transform: rotate(-20deg);
}

.logo {
  cursor: pointer;
  width: 100%;
  height: 100%;

  padding: 0;
  margin-left: 2vh;
  margin-top: 7vh;

  .logo-svg {
    width: 100%;
    height: 100%;
    padding: 0;
    margin-left: 0%;
    background-size: 100%;
    transform: scale(2);
  }
}

@media only screen and (max-width: 800px) {
  .logo {
    margin-left: 0vh;
  }
}
