.home-button {
  background-color: #fdfcfc;
  margin: auto;
  margin-bottom: 4%;
  cursor: pointer;

  background-color: #f1f0ee;
  border-radius: 20vh;
  border: solid rgb(136, 124, 124) 2px;
  p {
    font-weight: 500;
  }
}

.home-button:hover {
  background-color: rgb(207, 199, 183);
  padding-bottom: 0.2vh;
}
.home-button-text-container {
  float: left;
  height: 100%;
  width: 75%;
  margin: 0;

  display: -webkit-flex;
  display: flex;
}
.home-notice-container {
  width: 5vh;
  height: 10vh;
  position: relative;
  left: calc(100% - 2.5vh);
  top: -2vh;
}
.home-notice-text {
  color: white;
  font-size: 3vh;
  margin: auto;
}
.home-notice {
  width: 5vh;
  height: 5vh;
  border-radius: 100%;
  background-color: rgb(248, 89, 89);
  position: absolute;

  display: -webkit-flex;
  display: flex;
}
.home-notice:hover {
  background-color: rgb(165, 70, 70);
  p {
    color: white;
  }
}
.home-button-text {
  margin: auto;
  text-align: center;
  font-size: 2vh;
  font-weight: 200;
  text-transform: uppercase;
  color: rgb(3, 3, 3);
}

.home-button-icon {
  width: 20%;
  height: 100%;
  float: right;
  margin-right: 5%;
  display: flex;
  * {
    margin: auto;
  }
}
.home-button {
  width: 100%;
  height: 9vh;
}

@media only screen and (max-width: 800px) {
  .home-button {
    width: 100%;
    margin-top: 1vh;
    margin-bottom: 0;
    border-radius: 0;
    height: 10vh;
    border-radius: 50px;

    p {
      font-size: 2vh;
    }
  }
}
