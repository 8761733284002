.score-board {
  width: 40vh;
  height: 60vh;
  background-color: rgb(255, 245, 241);
  border-radius: 4vh;
  p {
    font-weight: 600;
    color: rgb(51, 50, 50);
  }
  padding: 1vh;
  margin-left: auto;
}
.player-score-board {
  width: 34vh;
  height: 8vh;
  margin-top: 1vh;

  .player-score-board-value {
    font-size: 3vh;
    line-height: 8vh;
    height: 8vh;
    float: right;
  }
}
.score-board-title {
  width: 100%;
  border-bottom: solid rgb(212, 195, 195) 0.1vh;
  height: 10vh;
  p {
    font-size: 3vh;
    line-height: 10vh;
    text-align: center;
    font-weight: 600;
    color: rgb(78, 67, 67);
  }
}
.score-board-players {
  overflow: scroll;
  overflow-x: hidden;
  width: 38vh;
  height: 45vh;
}
.player-score-board-name {
  font-size: 3vh;
  line-height: 8vh;
  height: 8vh;
  float: left;
  overflow: hidden;
  max-width: 17vh;
}
.player-score-board-icon {
  font-size: 3vh;

  line-height: 8vh;
  width: 8vh;
  height: 8vh;
  text-align: center;
  background-color: rgb(65, 27, 100);
  border-radius: 100%;
  margin-right: 2vh;
  p {
    color: rgb(255, 255, 255);
    font-weight: 600;
  }
}
