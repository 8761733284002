.player-view {
  float: left;
  width: fit-content;
}
.player-view-circle {
  background-color: rgb(223, 144, 54);
  border: rgb(255, 255, 255) solid 0.2vh;
  width: 10vw;
  height: 10vw;
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  margin-left: 0.4vh;
  max-width: 8.5vh;
  max-height: 8.5vh;
  min-width: 6vh;
  min-height: 6vh;
  cursor: pointer;

  h1 {
    color: rgb(255, 255, 255);
    margin: auto;
    font-size: 2vh;
  }
}
.player-view-circle:hover {
  h1 {
    border-bottom: 1vh solid rgba(0, 0, 0, 0);
  }
  background-color: rgb(73, 27, 62);
}
.player-view-cons {
  * {
    float: left;
    font-size: 3vh;
    height: 2vh;
  }
}
.player-view-cons > *:hover {
  border-bottom: 0.30000000000000004vh solid rgba(0, 0, 0, 0);
}
