.lab-icon-whole {
}
.lab-icon {
  height: 10vh;
  width: 10vh;
  overflow: hidden;
  margin: auto;
  min-width: 10vh;
  min-height: 10vh;
}
.lab-icon-animation {
  width: fit-content;
  margin: auto;
}
.lab-fire {
  position: absolute;
  width: 10vh;
  height: 12vh;
  overflow: hidden;
  margin-top: -10vh;
}
.lab-flask {
  position: relative;
}
.bubbles {
  width: 1vh;
  height: 1vh;
  border-radius: 100%;
  background-color: rgb(0, 183, 255);
  position: absolute;
  animation: horizontalanim infinite linear, verticalanim infinite ease-in-out;
  animation-duration: 1s;
}
.bubbles-2 {
  width: 1vh;
  height: 1vh;
  border-radius: 100%;
  background-color: rgb(255, 0, 0);
  position: absolute;
  animation: horizontalanim infinite linear, vertical infinite ease-in-out;
  animation-duration: 1s;
  animation-delay: 0.4s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes horizontalanim {
  0% {
    margin-top: 12vh;
  }
  100% {
    margin-top: 0vh;
  }
}
/* Standard syntax */
@keyframes horizontalanim {
  0% {
    margin-top: 12vh;
  }
  100% {
    margin-top: 0vh;
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes verticalanim {
  0% {
    margin-left: 50vh;
  }
  30% {
    margin-left: 60vh;
  }
  60% {
    margin-left: 7.5vh;
  }
  100% {
    margin-left: 0vh;
  }
}

/* Standard syntax */
@keyframes verticalanim {
  0% {
    width: 0.5vh;
    height: 0.5vh;
    margin-left: 5vh;
  }
  30% {
    width: 1vh;
    height: 1vh;
    margin-left: 6vh;
  }
  60% {
    width: 0.5vh;
    height: 0.5vh;
    margin-left: 8vh;
  }
  90% {
    width: 1vh;
    height: 1vh;
    margin-left: 4vh;
  }
  100% {
    width: 0.5vh;
    height: 0.5vh;
    margin-left: 5vh;
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes vertical {
  0% {
    margin-left: 50vh;
  }
  30% {
    margin-left: 60vh;
  }
  60% {
    margin-left: 7.5vh;
  }
  100% {
    margin-left: 0vh;
  }
}

/* Standard syntax */
@keyframes vertical {
  0% {
    width: 0.5vh;
    height: 0.5vh;
    margin-left: 5vh;
  }
  30% {
    width: 1vh;
    height: 1vh;
    margin-left: 2vh;
  }
  60% {
    width: 0.5vh;
    height: 0.5vh;
    margin-left: 0vh;
  }
  90% {
    width: 1vh;
    height: 1vh;
    margin-left: 20h;
  }
  100% {
    width: 0.5vh;
    height: 0.5vh;
    margin-left: 1.8vh;
  }
}
