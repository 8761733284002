.home-buttons {
  width: 45vh;
  margin: auto;
  margin-top: 17vh;
}

@media only screen and (max-width: 800px) {
  .home {
  }
}

@media only screen and (max-width: 1100px) {
  .home {
  }

  .home-robot-icon {
    bottom: 4vh;
    margin-left: -40vh;
    transform: scale(-1, 1);
    .robot-talk-bubble {
      display: none;
    }
    .robot-icon {
      visibility: hidden;
      transition: visibility 0s 2s;
    }
    transition: ease margin-left 2s linear;
  }
  .home-robot-icon::after {
  }
}

.robot-icon:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  margin-left: 4vh;
  transform: rotate(-9deg);
  transition: ease margin-left 0.5s, ease rotate 0.5s;
}

.home-robot-icon {
  position: absolute;
  bottom: 0vh;
  left: 0vh;
  transition: ease margin-left 2s;
  cursor: pointer;
}
.robot-talk-bubble {
  bottom: 1.6vh;
  position: absolute;
  left: 5vh;
  bottom: 8vh;
}

.home-robot-icon {
  height: fit-content;

  width: 23vh;
}
.robot-icon {
  width: 23vh;
  position: absolute;
  transform: rotate(15deg);
  bottom: 1.6vh;
  left: -6vh;
}
