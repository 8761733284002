.about-container {
  margin-top: 5vh;
  display: flex;
}
#about-board {
  margin: auto;
  width: 90vw;
  height: 80vh;
}
.about-board-conent {
  height: 60vh;
  width: 83vh;

  overflow-x: hidden;
  overflow-y: scroll;
}
.about-board-panel {
  width: 80vh;
  max-width: 85vw;
  overflow: hidden;
}
.about-board-contact {
  background-color: rgba(151, 102, 230, 0);
  width: 38vw;
  height: 60vh;
  top: 30vh;
  left: 54vw;

  padding: 10vh;
  border-left: solid rgb(238, 231, 231) 2px;
  .about-board-form {
    width: 50%;
    font-weight: 800;
    float: left;
  }
  position: absolute;
}
.about-board-help-image {
  margin-top: 10vh;
  width: 20vh;
}
.about-board-form-button {
  margin-top: 10vh;
  float: right;
}
.about-board-row {
  width: 80vh;
  margin-top: 6vh;
  height: fit-content;
  line-height: 40px;
  font-size: 30px;
  max-width: 85vw;
  p {
  }
  img {
    width: 24vh;
  }
  h3 {
    font-size: 60px;
  }
}
.about-icon-right {
  float: right;
  background-color: color;
  padding-top: 5vh;
  padding-right: 0vh;
  padding-left: 5vh;
  padding-bottom: 7vh;
  shape-outside: circle();
}

.about-icon-left {
  float: left;
  padding-top: 6vh;
  padding-right: 4vh;
  padding-bottom: 4vh;
  background-color: color;
  shape-outside: circle();
}
.about-board-contact {
  display: none;
}
#about-board {
  max-width: 85vh;
}
