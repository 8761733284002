.background-color {
  background-image: linear-gradient(0deg, rgb(58, 28, 88), rgb(34, 10, 56));
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.circle-1 {
  width: calc(60vh - 20vh);
  height: calc(60vh - 20vh);
  position: absolute;
  top: calc(40vh - 20vh);
  left: calc(70vw - 20vh);
  border-radius: 100%;
  z-index: 1;
  border: 1.5vh rgb(233, 195, 69) solid;
  display: block;
}
.circle-2 {
  width: calc(60vh - 20vh);
  height: calc(60vh - 20vh);
  position: absolute;
  top: calc(120vh - 30vh);
  left: calc(20vw - 30vh);
  border-radius: 100%;
  z-index: 1;
  border: 1.2000000000000002vh rgb(226, 43, 43) solid;
  display: block;
}
.circle-3 {
  width: calc(80vh - 20vh);
  height: calc(80vh - 20vh);
  position: absolute;
  top: calc(1vh - 20vh);
  left: calc(1vw - 20vh);
  border-radius: 100%;
  z-index: 1;
  border: 3vh rgb(95, 23, 143) solid;
  display: block;
}

.circle-4 {
  width: calc(80vh - 20vh);
  height: calc(80vh - 20vh);
  position: absolute;
  bottom: calc(-50vh);
  right: calc(1vw - 20vh);
  border-radius: 100%;
  z-index: 1;
  border: 1vh rgb(32, 180, 27) solid;
  display: block;
}
