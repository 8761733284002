.setting-button {
  width: 100%;
  height: 85%;
  float: left;
  background-color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
  border-radius: 2vh;
  overflow: hidden;
  margin-top: 1vh;
}

.game-view {
  width: 70vw;
  height: 80vh;
  margin: auto;
  max-width: 60vh;
  border-radius: 10vh;

  @media only screen and (max-width: 60vh) {
    width: 100vw;
    height: 91vh;
  }
}
.players-view {
  background-color: rgb(4, 0, 255);
  * {
    margin-top: auto;
  }
}
