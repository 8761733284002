.question {
  width: 40vh;
  height: 60vh;

  .question-title {
    width: 40vh;
    height: 10vh;
    text-align: center;
    p {
      color: rgb(255, 255, 255);
      font-size: 3vh;
      font-weight: 400;
    }
  }
  #selected-question-winner {
    background-color: rgb(12, 30, 192);
    color: white;
  }

  .question-answer {
    .question-single-answer {
      width: 36vh;
      margin-left: 2vh;
      height: 8vh;
      margin-top: 1vh;
      line-height: 8vh;
      background-color: white;
      border-radius: 1vh;
      p {
        font-size: 2.5vh;
        font-weight: 400;
        padding-left: 2vh;
      }
    }
  }
}

#selected-question {
  background-color: rgb(128, 130, 243);
  color: rgb(219, 211, 211);
}
#selected-question-not {
  color: rgb(219, 211, 211);
}

.question-answer-winner {
  #selected-question {
    background-color: rgb(238, 79, 79);
    color: rgb(219, 211, 211);
  }

  .question-single-answer-loser {
    width: 36vh;
    margin-left: 2vh;
    height: 8vh;
    margin-top: 1vh;
    line-height: 8vh;
    border-radius: 1vh;
    background-color: white;
    p {
      font-size: 2.5vh;
      font-weight: 400;
      padding-left: 2vh;
    }
  }
  .question-single-answer-winner {
    width: 36vh;
    margin-left: 2vh;
    height: 8vh;
    margin-top: 1vh;
    line-height: 8vh;
    background-color: rgb(24, 224, 34);
    border-radius: 1vh;
    p {
      font-size: 2.5vh;
      font-weight: 400;
      padding-left: 2vh;
    }
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes correct {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
/* Standard syntax */
@keyframes correct {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
