.loading-panel {
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  height: 100vh;
  color: white;
}
.loading-panel-spinner {
  margin: auto;
  height: 20vh;
  width: 20vh;
  * {
    height: 20vh;
    width: 20vh;
  }
}
.loading-text {
  margin: auto;
  color: white;
}
.loading-title {
  position: absolute;
  margin: auto;
  display: flex;
}
