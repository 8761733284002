.robot-talk-bubble-image {
  width: 20vw;
  opacity: 0.2;
}
.robot-talk-text {
  width: 13vw;
  height: 10vw;
  overflow: scroll;
  opacity: 1;
  position: absolute;
  top: 15%;
  left: 20%;
  p {
    color: white;
    font-weight: 500;
  }
}
.robot-talk-bubble {
  height: 18vh;
}
