body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  * {
    font-weight: 500;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  font-weight: 500;
  font-size: 2vh;
}
button {
  box-shadow: none;
  width: 18vh;
}
button:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
button:active {
  .login-screen-static-arrow {
    animation: slidein 0.2s 1;
  }
}

u {
  border: none;
}
.alert {
  z-index: 100;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
@keyframes slidein {
  from {
  }

  to {
    transform: rotate(360deg);
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar-track {
  border-radius: 1vh;
  background-color: #8d090900;
}

::-webkit-scrollbar {
  width: 1vh;
  background-color: #ffffff00;
  border: 0;
  padding-bottom: 10vh;
  height: 0vh;
}

::-webkit-scrollbar-thumb {
  border-radius: 1vh;
  background-color: rgb(180, 164, 164);
  height: 5vh;
  max-height: 1vh;
}
