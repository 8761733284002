.user-exist{
    margin-top:2vh;
    color:rgb(11, 128, 36);
    float: left;
}
.user-doesnt-exist{
    margin-top:2vh;
    color:red;
    float: left;
}
.user-exist-button{
    margin-top:2vh;
    float: right;
}
