.invites {
}
.invites-board:hover {
  cursor: pointer;

  .invites-board-value {
    p {
      display: none;
    }
  }
  .invites-board-value::after {
    content: "➡️";
  }
  p {
    color: rgb(179, 166, 166);
  }
}
.invites-container {
  margin-top: 10vh;
  display: flex;

  .score-board {
    margin: auto;
  }
}
.score-board {
  width: 40vh;
  height: 60vh;
  background-color: rgb(255, 245, 241);
  border-radius: 4vh;
  p {
    font-weight: 600;
    color: rgb(51, 50, 50);
  }
  padding: 1vh;
  margin-left: auto;
}
.invites-board {
  width: 34vh;
  height: 8vh;
  margin-top: 1vh;

  .invites-board-value {
    font-size: 3vh;
    line-height: 8vh;
    height: 8vh;
    float: right;
  }
}
.score-board-title {
  width: 100%;
  border-bottom: solid rgb(212, 195, 195) 0.1vh;
  height: 10vh;
  p {
    font-size: 3vh;
    line-height: 10vh;
    text-align: center;
    font-weight: 600;
    color: rgb(78, 67, 67);
  }
}
.score-board-players {
  overflow: scroll;
  overflow-x: hidden;
  width: 38vh;
  height: 45vh;
}
.invites-board-name {
  font-size: 3vh;
  line-height: 8vh;
  height: 8vh;
  float: left;
  overflow: hidden;
  width: 50%;
  float: left;
}
.invites-board-icon {
  font-size: 3vh;
  float: left;
  line-height: 8vh;
  width: 8vh;
  background-color: green;
  height: 8vh;
  text-align: center;
  background-color: rgb(65, 27, 100);
  border-radius: 100%;
  margin-right: 2vh;
  p {
    color: rgb(255, 255, 255);
    font-weight: 600;
  }
}
