.signup-panel-single {
  width: 40vh;
  height: 40vh;
}
.phone-land-number {
  width: 20%;
}
.phone-land-number {
  width: 25%;
  float: left;
  margin-left: 10%;
}
.form-phone-number {
  width: 50%;
  float: right;
  margin-right: 10%;
}
.code-panel-single {
  width: 5vh;
  height: 5vh;
  float: left;
  margin: auto;
  text-transform: uppercase;
}

.login-icon {
  height: 10vh;
  position: absolute;
  left: calc(50vw - 7.5vh);
  margin-top: -9vh;
  width: 15vh;
  height: 15vh;
  color: #4c4c77;
  z-index: 3;
}
.login-icon-cable {
  width: 50vh;
  height: 50vh;
  margin-top: -15vh;
  margin-left: -9.4vh;
  transform: scale(-1, 1);
  transform: rotate(25deg);
}
.login-icon-cable-container {
  width: 26vh;
  height: 20vh;
  margin-top: -6.7vh;
  margin-left: 0vh;
  position: absolute;
  overflow: hidden;
  z-index: 2;
  animation: 15s cableAnimation infinite;
  color: #99990f;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes cableAnimation {
  0% {
    transform: scale(-0.2, 1);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(-0.2, 1);
  }
}
/* Standard syntax */
@keyframes cableAnimation {
  0% {
    transform: scale(1, -1);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, -1);
  }
}

.login-screen {
  z-index: 2;
  position: relative;
}
.code-form-numbers {
  margin-top: -3vh;
  width: 100%;
  height: 10vh;
  display: -webkit-flex;
  display: flex;
  padding-left: 1vh;
  padding-right: 1vh;
}
