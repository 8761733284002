.drop-down{
    button{
        background-color: rgba(255, 255, 255, 0) !important;
        color:#0a0a0a !important;
        width:12vh;
        padding: 0 !important;
        margin: 0 !important;
        border:0;
    }
    button:hover{
        color:rgb(10, 10, 10) !important;
    }  
    button:left{
        color:rgb(10, 10, 10) !important;
    }  

    button:focus{
        color:rgb(10, 10, 10) !important;
    }

    
    border-bottom: solid rgb(223, 216, 216) 0.1vh;

}
.settings-drop-down:hover{
    border-bottom: solid rgb(141, 138, 138) 0.1vh;
}