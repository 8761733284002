.multiple-sceen-panel{
    position: relative;
    div{
        float:left;
    }
}
.single-sceen-panel{
    overflow: hidden;
   
}

