.side-bar-header {
  position: absolute;
  background-color: rgb(241, 234, 222);

  height: fit-content;
  width: 28vw;
  right: 1vh;
  margin-top: 11vh;
  border-radius: 1vh;
  border: solid rgb(180, 175, 175) 2px;
  padding: 10px;
  z-index: 100;

  .side-bar-button {
    margin-top: 13vh;
    width: 98%;
    height: 5vh;
  }

  img {
    width: 100%;
    height: 12vh;
    padding-bottom: 2vh;
    border-bottom: solid rgb(209, 190, 190) 2px;
  }

  h3 {
    padding-top: 1vh;
    padding-bottom: 1vh;
    color: rgb(59, 53, 53);
    float: left;
    width: 100%;
    height: fit-content;
    text-align: left;
    font-size: 20px;
    border-bottom: solid rgb(231, 221, 221) 2px;
    &:hover {
      color: rgb(206, 188, 188);
    }
  }
  max-width: 40vh;
}

.side-bar-header:hover {
  position: absolute;
  background-color: rgb(241, 234, 222);

  height: fit-content;
  width: 28vw;
  right: 1vh;
  margin-top: 11vh;
  border-radius: 1vh;
  border: solid rgb(180, 175, 175) 2px;
  padding: 10px;
  img {
    width: 100%;
    height: 12vh;
    padding-bottom: 2vh;
    border-bottom: solid rgb(209, 190, 190) 2px;
  }
  h3 {
    padding-top: 1vh;
    padding-bottom: 1vh;
    float: left;
    width: 100%;
    height: fit-content;
  }
}
