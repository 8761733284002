.lang-selector-dd-flag {
  .lang-selector-flag {
    :hover {
      border-bottom: solid 0.4vh rgba(255, 255, 255, 0);
    }
    padding-left: 0.4vh;
    padding-bottom: 0.1vh;
  }
}

.lang-selector-form {
  width: 15vh;

  * {
    text-align: center;
  }
  p {
    color: black;
  }
  .lang-selector-dd-item {
    p:hover {
      border-bottom: solid rgb(189, 188, 188) 0.1vh;
    }
    .lang-selector-flag {
      float: left;
      * {
        position: absolute;
        float: left;
      }
    }
  }
}
