.incorrect-panel {
  display: -webkit-flex;
  display: flex;
}

.incorrect-icon {
  margin: auto;
  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 0.5s;
  width: 80%;
  height: 80%;
}
.incorrect-text {
  position: absolute;
  width: 40vh;
  top: calc(40vh - 5vh);
  text-align: center;
  margin-top: -2vh;
  color: rgb(71, 69, 69);
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
  0% {
    width: 0;
    height: 0;
  }
  40% {
    width: 0;
    height: 0;
  }
  100% {
    width: 80%;
    height: 80%;
  }
}
/* Standard syntax */
@keyframes example {
  0% {
    width: 0;
    height: 0;
  }
  40% {
    width: 0;
    height: 0;
  }
  100% {
    width: 80%;
    height: 80%;
  }
}
