.menu-button {
  background-color: #ffffff;

  height: 11%;
  margin: auto;
  margin-bottom: 4%;
  max-width: 50vh;
  cursor: pointer;
  min-height: 5vh;
  color: #ffffff;
  font-weight: 500;
}

.menu-button:hover {
  background-color: rgb(248, 238, 238);
  border-bottom: 1vh solid rgba(0, 0, 0, 0);
}
.menu-button-text-container {
  float: left;
  height: 100%;
  width: 85%;

  display: -webkit-flex;
  display: flex;
  padding: 0;

  * {
    margin: auto;
  }
}
.menu-notice-container {
  width: 5vh;
  height: 10vh;
  position: relative;
  left: calc(100% - 2.5vh);
  top: -2vh;
}
.menu-notice {
  width: 5vh;
  height: 5vh;
  border-radius: 100%;
  background-color: rgb(248, 89, 89);
  position: absolute;
  display: -webkit-flex;
  display: flex;
  p {
    font-size: 2vh;
    margin: auto;
  }
}
.menu-button-text {
  font-size: 1.4vh;
  width: 80%;
  padding: 0;
  margin-left: 5%;
}

.menu-button-icon {
  width: 10%;
  height: 100%;
  float: right;
  margin-right: 5%;
}
.menu-button {
  width: 25%;
  height: 6vh;
}
#selected-setting {
  border-bottom: solid #ffffff 10px;
}
