.timer {
  display: flex;
  width: 40vh;
}
.question-time-view {
  background-color: rgba(219, 213, 213, 0.308);
  width: 36vh;
  height: 1.4000000000000001vh;
  overflow: hidden;
  margin: auto;
  border-radius: 1vh;
}

.question-time-bar {
  background-color: rgb(204, 73, 154);

  width: 100%;
  height: 1.4000000000000001vh;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes makeFull {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
/* Standard syntax */
@keyframes makeFull {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
