.game {
  display: flex;
  padding-top: 10vh;
  .game-panel {
    margin: auto;
    .game-screen {
      width: 100%;
    }
  }
}
