.add-question-animation {
  width: 6vh;
  height: 6vh;
  position: absolute;
  transform: rotate(0deg);
}
.add-question-animation-running {
  margin-top: -10vh;
  margin-left: 4vh;
  width: 6vh;
  height: 6vh;
  animation: fly 1s ease-in-out;
  opacity: 0;
}
.add-question-animation-writing {
  margin-top: -3vh;
  margin-left: 7vh;
  transform: rotate(70deg);
  animation: draw 4s;
  color: #c3c3c3;
}
.cube1 {
  position: absolute;
  width: 6vh;
  height: 6vh;
  margin-top: 2.7vh;
}
.cube2 {
  position: absolute;
  width: 10vh;
  height: 10vh;
  margin-left: 2vh;
  bottom: 0.2vh;
}
.cube3 {
  position: absolute;
  width: 6vh;
  height: 6vh;
}
.cube4 {
  position: absolute;
  width: 6vh;
  height: 6vh;
  margin-top: 3vh;
  margin-left: 13vh;
}
.robot-create {
  position: absolute;
  width: 6vh;
  height: 6vh;
  margin-left: 14vh;
  margin-top: 1vh;
  transform: rotateY(180deg);
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes fly {
  10% {
    opacity: 1;
  }
  30% {
    margin-left: 20vh;
  }
  60% {
    margin-left: 30vh;
  }
  100% {
    margin-top: -60vh;
    margin-left: 40vh;
    width: 10%;
  }
}
/* Standard syntax */
@keyframes fly {
  10% {
    opacity: 1;
  }
  30% {
    margin-left: 20vh;
  }
  60% {
    margin-left: 30vh;
  }
  100% {
    margin-top: -60vh;
    margin-left: 40vh;
    width: 10%;
  }
}
