.login {
  height: 100vh;
  width: 100vw;
}
.login-panel {
  padding-top: 10vh;
  width: 70vw;
  height: 70vh;
  margin: 5vh auto;
  max-width: 60vh;
  min-width: 43vh;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
}
.login-panel-description-exist {
  color: green;
  width: 100%;
  margin-bottom: 2vh;
  p {
    max-width: 30vh;
    width: fit-content;
    margin: auto;
    padding: 1vh;
    border-radius: 1vh;
    box-shadow: 1vh solid black;
    height: 5vh;
    text-align: center;
  }
}
.login-panel-description-not-exist {
  color: red;
  width: 100%;
  margin-bottom: 2vh;
  p {
    max-width: 30vh;
    width: fit-content;
    margin: auto;
    padding: 1vh;
    border-radius: 1vh;
    box-shadow: 1vh solid black;
    height: 5vh;
    text-align: center;
  }
}

.login-icon {
  background-color: rgb(235, 227, 227);
  padding: 20px;
  border-radius: 100%;
  width: 100vh;
}
.forgot {
  color: rgb(134, 130, 130);
  margin-left: 4vh;
}
.login-screen {
  background-color: rgb(246, 246, 246);
  overflow: hidden;
  border: solid rgb(235, 235, 235) 0.1vh;
  width: 40vh;
  height: 40vh;
  margin-left: calc(50% - 20vh);
  margin-bottom: 5vh;
  color: #ffe600;
}

.login-panel-single {
  width: 40vh;
  height: 40vh;
}
.form {
  margin-left: 10%;
  width: 80%;
  margin-top: 5%;
  float: bottom;
  margin-bottom: 1vh;
}
.title-box-panel {
  text-align: center;
  width: 100%;
  height: 30%;
  line-height: 100%;
  h1 {
    position: relative;
    margin-top: calc((50% - 3vh) / 2);
    font-size: 3vh;
    height: 10vh;
    width: 100%;
    color: rgb(0, 0, 0);
  }
}

.login-screen-static {
  width: 40vh;
  margin: auto;
  height: 15vh;
  margin-left: calc(50% - 20vh);
}
