.settings-buttons {
  height: 2%;
  width: 100%;
  * {
    font-size: 1.5vh;
  }
}
.game-settings {
  height: calc(68vh - 0.30000000000000004vh);
  width: 100;
  padding: 0;
  position: relative;
}
.answer-icon {
  display: flex;
  * {
    margin: auto;
  }
  position: relative;
  width: 5%;
  height: 3.5vh;
  padding-left: 0.5vh;
}

.question-form {
  .question-row {
    overflow: hidden;
  }
  width: 100%;
  height: 50vh;
  margin-top: -5vh !important;
  padding: 0 !important;
}
.setting-body-item-1st {
  float: left;
  line-height: 3.5vh;
  width: fit-content;
}
.setting-body-item-2st {
  float: right;
  height: 5vh;
}
.setting-body {
  margin: 10%;
  margin-left: 10%;
}
.setting-body-item {
  height: 5vh;
  width: 76%;
}
.settings-body-info {
  width: calc(100% - 10vw - 10%);
}
.top-panel-game-settings {
  background-color: rgb(54, 16, 179);
  width: 100%;
  height: 2vh;
}
.settings-buttons > * {
  float: left;
  width: 25%;
  border-radius: 0;
}
.settings-body-icon {
  float: right;
  position: absolute;
  margin-top: 15%;
  right: 5%;
  cursor: grab;
}
.add-new-question-animation-icon {
  position: absolute;
  left: 7.5vh;
  bottom: 0.5vh;

  width: 11vh;
  height: 11vh;
  border-radius: 100%;
}
.settings-body-icon-2 {
  width: 50vh;
  height: 10vh;
  position: absolute;
  bottom: 5%;
  left: 8%;
}
.settings-body-icon:active {
  float: right;
  animation: shake 1s infinite;
}
.lab-icon-settings {
  float: right;
  width: 200%;
}
.settings-body-header {
  margin-left: 10%;
  border-bottom: 0.1vh solid rgb(231, 226, 226);
  width: 80%;
}
.settings-buttons > #selected-notice {
  float: left;
  width: calc(25% - 1vh);
}
.setting-button-move-on {
  position: absolute;
  bottom: 5%;
  right: 8%;
}
.question-row {
  width: 100%;
  display: block;
  overflow: visible;
  padding: 0;

  padding-top: 1%;
  padding-bottom: 1%;
}
.first-question-row {
  border-bottom: solid rgb(214, 207, 207) 0.1vh;
  display: block;
  overflow: hidden;

  margin-bottom: 3%;
  padding-bottom: 5%;
}
.question-input,
.question-input-single {
  width: 50%;
  float: right;
  line-height: 2vh;
  resize: none;
  margin-top: 1%;
  margin-right: 1%;
}
.review-game-icon {
  width: 10vh;
  height: 10vh;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.1vh, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(0.2vh, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-0.4vh, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(0.4vh, 0, 0);
  }
}
