.settings-board-value {
  float: right;
  height: 100%;

  display: flex;
  * {
    transform: scale(1.3);
    margin: auto;
  }
}
.settings-settings {
  height: 47vh;
  overflow: scroll;
}
