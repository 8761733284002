.no-page-title {
  font-size: 13vh;
  width: 100vw;
  color: white;
  display: flex;
  p {
    font-size: 14vh;
    margin: auto;
    font-weight: 400;
    text-shadow: 2px 2px #1d1d1d;
  }
}

.no-page-description {
  font-size: 5vh;
  width: 100vw;
  display: flex;
  color: white;
  p {
    margin: auto;
    font-weight: 400;
    text-shadow: 2px 4px #1d1d1d;
  }
}
.no-page-robot-container {
  width: 30vh;
  height: 30vh;
  border-radius: 100%;
  display: flex;
  background-color: wheat;
  * {
    margin: auto;
  }
  margin: auto;
  margin-top: 10vh;
}
.no-page-question-mark1 {
  * {
    font-size: 6vh;
    position: absolute;
    margin-top: -22vh;
    margin-left: 2vh;
  }
}
.no-page-question-mark2 {
  * {
    font-size: 6vh;
    position: absolute;
    margin-top: -18vh;
    margin-left: 10vh;
    transform: rotate(-10deg);
  }
}
.no-page-question-mark3 {
  * {
    font-size: 6vh;
    position: absolute;
    margin-top: -22vh;
    margin-left: 20vh;
    transform: rotate(10deg);
  }
}
.no-page-go-back {
  width: 15vh;
  height: 15vh;
  bottom: 0;
  border-radius: 100%;
  cursor: pointer;
  position: absolute;
  display: flex;
  * {
    margin: auto;
    font-size: 6vh;
  }
}

.no-page-go-back:hover {
  background-color: rgba(245, 222, 179, 0.219);
}
