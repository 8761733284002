.question-icon {
  background-color: white;
  width: 15vh;
  height: 15vh;
  margin: auto;
  display: flex;
  border-radius: 100%;
}
.question-icon {
  img {
    width: 10vh;
    height: 10vh;
    margin: auto;
  }
}

.question-icon-whole {
  display: flex;
  width: 40vh;
  height: 20vh;
}
